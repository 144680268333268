<template>
  <div>
    <div class="userManagement">
      <userSearch @getData="getData" @addNewUser="addNewUser"
      @setPageInfo="setPageInfo" @getSearch="getSearch"></userSearch>
      <div class="tablePart">
        <div class="">
          <el-table :data="data.tableList" style="width: 100%" v-loading="data.loading">
            <el-table-column prop="userId" label="用户ID" align="center"> </el-table-column>
            <el-table-column prop="nickName" label="用户昵称" align="left"> </el-table-column>
            <el-table-column prop="title" label="用户头像" align="center">
              <template v-slot="scope">
                <div class="imgNameBox">
                  <div class="img">
                    <img class="table-td-thumb" style="width:64px;height:64px" :src="scope.row.icon" alt="缩略图" />
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="roleId" label="用户类型" align="center">
              <template v-slot="scope">
                {{ filterRole(scope.row.roleId) }}
              </template>
            </el-table-column>
            <el-table-column prop="userId" label="QQ号码" align="center"> </el-table-column>
            <el-table-column label="关联社区" align="center" show-overflow-tooltip>
              <template v-slot="scope">
                <span  v-if="scope.row.roleId === 1">
                  ALL
                </span>
                <span  v-else>
                  <span v-for="item in scope.row.communityList" :key="item.id"> {{ item.name }}; </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="creatorNickName" label="创建人" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center">
              <template v-slot='scope'>
                {{timetrans(scope.row.createTime)}}
              </template>
            </el-table-column>
            <el-table-column prop="totalPostSendNum" label="发帖量" align="center"> </el-table-column>
            <el-table-column prop="totalPostAuditNum" label="审核帖子量" align="center"> </el-table-column>
            <el-table-column fixed='right' label="操作"  width='120'>
              <template #default="scope" >
                <div class="btn-box">
                  <el-button
                    type="text"
                    @click="handleEdit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    style="
                      color: red;
                    "
                    v-if="userInfo.roleId === 1"
                    type="text"
                    @click="deleteRow(scope.$index, data.tableList, scope.row)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    @click="handleData(scope.row)"
                    >数据</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="reply-pagination">
          <el-pagination
            style="float: right; padding-top: 10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.pageNum"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageInfo.pageSize"
            layout="sizes, prev, pager, next"
            :total="pageInfo.total"
          >
          </el-pagination>
        </div>
        <div class="addNewUserDialog">
          <el-dialog
            v-model="data.addUserBox"
            :title="data.userSearchData.title"
            destroy-on-close
            :width="data.userSearchData.width"
            :top="data.userSearchData.top"
            :close-on-click-modal="false"
            @close="close"
          >
            <div class="addUserForm" v-if="data.newUser">
              <el-form label-width="80px" label-position="right" :model="data.userFormData" :rules='rules'
                       ref='newForm'>
                <el-form-item label="用户类型" prop='roleId'>
                  <el-select
                    v-model="data.userFormData.roleId"
                    placeholder="请选择活动用户类型"
                    style="width: 240px; height: 36px"
                    @change="changeRoleId"
                  >
                    <el-option
                      :label="item.name"
                      v-for="item in data.selectOption"
                      :key="item.value"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="QQ号码" prop="userId">
                  <el-input
                    type="userId"
                    v-model.number="data.userFormData.userId"
                    placeholder="请输入QQ号码"
                    style="height: 36px; line-height: 36px; width: 240px"
                    :disabled="data.isEdit"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="关联社区">
                  <el-button @click="openAddCommunity" :disabled="data.userFormData.roleId === 1">
                    <span style="margin-right: 6px">+</span>添加
                  </el-button>
                </el-form-item>
                <el-form-item>
                  <div class="communtiyList">
                    <ul>
                      <li v-for="(item, index) in data.userFormData.communityIdLista" :key="index">
                        <span class="text">{{ item.name }}</span
                        ><span class="icon">
                          <i class="el-icon-close" @click="delTag(index)"></i>
                        </span>
                      </li>
                    </ul>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="dataPage" v-if="data.dataPage">
              <div class="dataFromBox">
                <el-form>
                  <el-form-item label="用户昵称">
                    <span class="userNickNme">{{ data.dataPageData.nickName }}</span>
                    <el-button
                      class="downBtn"
                      @click="
                        handleDownloadData(
                          data.dataPageData.nickName,
                          data.dataPageData.startTimeStamp,
                          data.dataPageData.endTimeStamp
                        )
                      "
                    >
                      <i class="el-icon-download"></i>下载数据
                    </el-button>
                  </el-form-item>
                  <el-form-item label="检索时间">
                    <el-date-picker
                      style="width: 282px"
                      v-model="data.dataPageData.pickTime"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      @change="changeTime"
                    >
                    </el-date-picker>
                    <el-button class="dataSearchBtn" type="primary" @click="dataSearch">检索</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <div class="dataTableBox" style="padding-bottom: 50px">
                <el-table
                  height="289"
                  border
                  :data="data.dataTable"
                  style="width: 100%"
                  v-loading='data.dataLoading'
                >
                  <el-table-column prop="createDate" label="日期" align="center">
                  </el-table-column>
                  <el-table-column prop="createPostNum" label="发帖量" align="center">
                  </el-table-column>
                  <el-table-column prop="auditPostNum" label="帖子审核量" align="center"> </el-table-column>
                  <el-table-column prop="auditCommentNum" label="回复审核量" align="center"> </el-table-column>
                </el-table>
                <el-pagination
                  style="float: right; padding-top: 10px"
                  @size-change="dataHandleSizeChange"
                  @current-change="dataHandleCurrentChange"
                  :current-page="dataPageInfo.pageNum"
                  :page-sizes="[5, 10, 15, 20]"
                  :page-size="dataPageInfo.pageSize"
                  layout="sizes, prev, pager, next"
                  :total="data.dataTable.length"
                >
                </el-pagination>
              </div>
            </div>
            <template #footer v-if="data.newUser">
              <span class="dialog-footer">
                <el-button @click="data.addUserBox = false">取 消</el-button>
                <el-button type="primary" @click="addUserBtn">确 定</el-button>
              </span>
            </template>
          </el-dialog>
          <el-dialog
            width="662px"
            title="关联社区添加"
            v-model="data.addCommunity"
            destroy-on-close
            :close-on-click-modal="false"
            @close="close(2)"
          >
            <el-form :inline="true">
              <el-form-item label="关联社区ID">
                <el-input placeholder="请输入关联Id社区ID" class="iniput" v-model="data.getComParmas.id"></el-input>
              </el-form-item>
              <el-form-item label="关联社区名称">
                <el-input placeholder="请输入社区名称" class="iniput" v-model="data.getComParmas.name"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="
                    width: 90px;
                    height: 36px;
                    padding: 0;
                    line-height: 36px;
                    min-height: 36px;
                    box-sizing: border-box;
                    margin-left: 6px;
                  "
                  @click="searchCom"
                  >检索</el-button
                >
              </el-form-item>
            </el-form>
            <div class="userInfoList">
              <el-table
                height="493"
                :data="data.communityTableList"
                v-loading='data.innerLoading'
              >
                <el-table-column
                  prop='id'
                  label="社区ID"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop='name'
                  label="社区名称"
                  align="center"
                >
                <template v-slot="scope">
                  <div :title='scope.row.name'>
                    {{scope.row.name}}
                  </div>
                </template>
                </el-table-column>
                <el-table-column
                  prop='icon'
                  label="社区ICON"
                  align="center"
                >
                  <template v-slot="scope">
                    <div class="imgNameBox">
                      <div class="img">
                        <img class="table-td-thumb" :src="scope.row.icon" alt="缩略图" />
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template v-slot="scope">
                    <el-button @click="handleAdd(scope.row)" class="addComBtn">添加</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                style="float:right;padding-top:10px"
                @size-change="communityHandleSizeChange"
                @current-change="communityHandleCurrentChange"
                :current-page="communityPage.pageNum"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="communityPage.pageSize"
                layout="sizes, prev, pager, next"
                :total="data.communityTableList.length">
              </el-pagination>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, provide, ref, computed } from 'vue';
import { getManagementUserList, getCommunityList, addUser, queryOperation, editUser, delUser, getSelf } from '@/reactivity/user';
import userSearch from '@/components/common/userSearch';
import exportJsonToExcel from '@/vendor/Export2Excel';
import { ElMessage, ElMessageBox } from 'element-plus';
import { filterRole } from '@/utils/common';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
export default defineComponent({
  components: {
    userSearch,
  },
  setup() {
    const store = useStore();
    onMounted(async () => {
      await getTableData(pageInfo);
    });

    // 定义翻页----------------------------------------------------------------
    const pageInfo = reactive({
      pageNum: 1,
      pageSize: 5,
      total: 0,
    });

    // 定义翻页----------------------------------------------------------------
    const dataPageInfo = reactive({
      pageNum: 1,
      pageSize: 5,
      total: 0,
    });

    // 定义社区列表翻页
    const communityPage = reactive({
      pageNum: 1,
      pageSize: 5,
    });

    // 定义数据----------------------------------------------------------------
    const data = reactive({
      dataLoading: false,
      innerLoading: false,
      loading: false,
      tableList: [],
      communityTableList: [],
      userSearchData: {
        loginUserId: '', // 登录人用户ID：openid;
        loginRoleId: '', // 登录人角色ID：1-管理员 2-厂商 3-版主 4-写手
        uin: '', // 检索条件1（管理员可用 非必填 ）：QQ号
        nickName: '', // 检索条件2（管理员可用 非必填 ）：用户昵称
        roleId: '', // 检索条件3（管理员可用 非必填 ）：用户角色类型 1-管理员 2-厂商 3-版主 4-写手
        communityId: '', // 检索条件4（管理员可用 非必填）：关联社区ID
        communityName: '', // 检索条件5（管理员可用 非必填）：关联社区名称
        pageNum: pageInfo.pageNum, // 请求分页第几页：填0 后台默认值1
        pageSize: pageInfo.pageSize, // 请求分页每页记录数：填0 后台默认值10
      },
      addUserBox: false,
      addCommunity: false,
      isEdit: false,
      title: '',
      width: '',
      top: '',
      selectOption: [
        {
          name: '管理员',
          value: 1,
        },
        {
          name: '厂商',
          value: 2,
        },
        {
          name: '版主',
          value: 3,
        },
        {
          name: '写手',
          value: 4,
        },
      ],
      userFormData: {
        roleId: '',
        userId: '',
        communityIdList: [],
        communityIdLista: [],
      },
      getComParmas: {
        id: '',
        name: '',
        relateAppId: '',
        relateUser: '',
        status: '',
        createBeginTime: '',
        createEndTime: '',
        pageNum: communityPage.pageNum,
        pageSize: communityPage.pageSize,
      },
      communityListA: [],
      communityList: [],
      newUser: false,
      dataPage: false,
      dataPageData: {
        userId: '',
        nickName: '',
        pickTime: [],
        startTimeStamp: null,
        endTimeStamp: null,
        pageNum: dataPageInfo.pageNum,
        pageSize: dataPageInfo.pageSize,
      },
      dataTable: [],
      dataTable1: [],
      dataToal: null,
      searchForm: {
        userId: '', // 检索条件1（管理员可用 非必填 ）：QQ号
        nickName: '', // 检索条件2（管理员可用 非必填 ）：用户昵称
        roleId: 0, // 检索条件3（管理员可用 非必填 ）：用户角色类型 1-管理员 2-厂商 3-版主 4-写手
        communityId: undefined, // 检索条件4（管理员可用 非必填）：关联社区ID
        communityName: '', // 检索条件5（管理员可用 非必填）：关联社区名称
        pageSize: 5,
        pageNum: 1,
      },
    });
    // 定义创建用户表单验证
    const newForm = ref(null);
    const rules = reactive({
      userId: [
        { required: true, message: '请填写被创建者QQ号', trigger: 'change' },
        { type: 'number', message: 'QQ号只能是数字', trigger: 'change' },
      ],
      roleId: [
        { required: true, message: '请选择用户类型', trigger: 'blur' },
        { type: 'number', message: 'QQ号只能是数字', trigger: 'change' },
      ],
    });
    const getSearch = (searchForm) => {
      pageInfo.pageSize = 5;
      pageInfo.pageNum = 1;
      data.searchForm = {
        userId: searchForm.userId, // 检索条件1（管理员可用 非必填 ）：QQ号
        nickName: searchForm.nickName, // 检索条件2（管理员可用 非必填 ）：用户昵称
        roleId: searchForm.roleId, // 检索条件3（管理员可用 非必填 ）：用户角色类型 1-管理员 2-厂商 3-版主 4-写手
        communityId: searchForm.communityId ? Number(searchForm.communityId) : null, // 检索条件4（管理员可用 非必填）：关联社区ID
        communityName: searchForm.communityName, // 检索条件5（管理员可用 非必填）：关联社区名称
        pageSize: pageInfo.pageSize,
        pageNum: pageInfo.pageNum,
      };
      getTableData({ ...data.searchForm });
    };
    const getTableData = (req) => {
      data.loading = true;
      const { roleId } = store.getters.userInfo;
      if (roleId === 1) {
        getManagementUserList(req).then((res) => {
          if (res.data?.ret === 0) {
            data.tableList = res.data.data.userDetailList;
            pageInfo.total = res.data.data.pageInfo.total;
            console.log(res.data.data.userDetailList, '90');
          } else {
            ElMessage.error(res.data?.msg);
          }
        })
          .finally(() => {
            data.loading = false;
          });
      } else {
        getSelf().then((res) => {
          if (res.data.ret === 0) {
            data.tableList = [res.data.data];
            pageInfo.total = 1;
          } else {
            ElMessage.error(res.data?.msg);
          }
        })
          .finally(() => {
            data.loading = false;
          });
      }
    };
    provide('pageInfo', pageInfo);
    provide('getTableData', getTableData);
    const getData = (val) => {
      data.userSearchData.loginRoleId = val.loginRoleId;
      data.userSearchData.communityId = val.communityId;
      data.userSearchData.communityName = val.communityName;
      data.userSearchData.nickName = val.nickName;
      data.userSearchData.loginRoleId = val.loginRoleId;
    };
    // 翻页方法----------------------------------------------------------------
    function handleSizeChange(val) {
      pageInfo.pageNum = 1;
      pageInfo.pageSize = val;
      data.searchForm.pageNum = 1;
      data.searchForm.pageSize = val;
      getTableData({ ...data.searchForm });
    }
    function handleCurrentChange(val) {
      pageInfo.pageNum = val;
      data.searchForm.pageNum = val;
      getTableData({ ...data.searchForm });
    }
    const setPageInfo = (searchForm) => {
      pageInfo.pageNum = 1;
      pageInfo.pageSize = 5;
      getSearch({ ...searchForm });
    };
    // 翻页方法----------------------------------------------------------------
    function communityHandleSizeChange(val) {
      communityPage.pageNum = 1;
      communityPage.pageSize = val;
      data.getComParmas.pageSize = val;
      getCommunty(data.getComParmas);
    }
    function communityHandleCurrentChange(val) {
      communityPage.pageNum = val;
      data.getComParmas.pageNum = val;
      getCommunty(data.getComParmas);
      // getPostList(val, 5);
    }
    // 翻页方法----------------------------------------------------------------
    function dataHandleSizeChange(val) {
      dataPageInfo.pageNum = 1;
      dataPageInfo.pageSize = val;
      data.dataPageData.pageSize = val;
      getUserTable(data.dataPageData);
    }
    function dataHandleCurrentChange(val) {
      dataPageInfo.pageNum = val;
      data.dataPageData.pageNum = val;
      getUserTable(data.dataPageData);
    }

    //  定义创建用户按钮----------------------------------------------------------------
    const addNewUser = () => {
      data.addUserBox = true;
      data.newUser = true;
      data.dataPage = false;
      console.log(data.addUserBox);
      data.userSearchData.top = '25vh';
      data.userSearchData.width = '560px';
      data.userSearchData.title = '用户信息';
      data.isEdit = false;
    };

    // 定义渲染对话框社区列表表格----------------------------------------------------------------
    const getCommunty = (req) => {
      data.innerLoading = true;
      getCommunityList(req).then((res) => {
        if (res.data?.ret === 0) {
          data.communityTableList = res.data.data.communities;
          data.communityTableList.length = res.data.data.pageInfo.total;
          data.communityTableList.length = res.data.data.pageInfo.total;
        } else {
          ElMessage.error(res.data?.msg);
        }
      })
        .finally(() => {
          data.innerLoading = false;
        });
    };

    // 定义打开关联社区列表方法----------------------------------------------------------------
    const openAddCommunity = () => {
      data.addCommunity = true;
      getCommunty(data.getComParmas);
    };
    // 定义检索社区发方法----------------------------------------------------------------
    const searchCom = () => {
      data.getComParmas.pageNum = 1;
      data.getComParmas.pageSize = 5;
      communityPage.pageNum = 1;
      communityPage.pageSize = 5;
      getCommunty(data.getComParmas);
    };
    // 定义添加社区方法----------------------------------------------------------------
    const handleAdd = (val) => {
      // ElMessage.closeAll();
      const comObj = {
        id: val.id,
        name: val.name,
      };
      data.userFormData.communityIdLista.push(comObj);
      const arr = [];
      Object.keys(data.userFormData.communityIdLista).forEach((item) => {
        arr.push(data.userFormData.communityIdLista[item].id);
      });
      if (new Set(arr).size !== arr.length) {
        console.log(arr);
        ElMessage.warning({
          message: '已关联，请勿重复添加哦~',
        });
      } else {
        ElMessage.success({
          message: '添加成功！',
        });
      }
      const hash = {};
      data.userFormData.communityIdLista = data.userFormData.communityIdLista.reduce((preVal, curVal) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        hash[curVal.id] ? '' : (hash[curVal.id] = true && preVal.push(curVal));
        return preVal;
      }, []);
    };

    // 定义关闭对话框重置方法----------------------------------------------------------------
    const close = (val) => {
      if (val === 2) {
        data.getComParmas.id = '';
        data.getComParmas.name = '';
      } else {
        data.userFormData.roleId = '';
        data.userFormData.userId = '';
        data.userFormData.communityIdLista = [];
        data.dataPageData.pickTime = [];
        data.dataPageData.endTimeStamp = undefined;
        data.dataPageData.startTimeStamp = undefined;
      }
    };

    // 定义管理员添加某个用户----------------------------------------------------------------
    const addOneUser = async (req) => {
      try {
        const res = await addUser(req);
        if (res.data.ret === 0) {
          ElMessage.success({
            message: '添加成功',
            type: 'success',
          });
          getTableData({ ...data.searchForm });
        } else {
          ElMessage.error(res.data.msg);
        }
      } catch (e) {
        console.log(e);
      }
    };
    // 定义管理员编辑某个用户----------------------------------------------------------------
    const editOneUser = async (req) => {
      editUser(req).then((res) => {
        console.log(res.data.ret);
        if (res.data.ret === 0) {
          ElMessage.success({
            message: '编辑成功',
            type: 'success',
          });
          data.addUserBox = !data.addUserBox;
          getTableData({ ...data.searchForm });
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    const addUserBtn = () => {
      // ElMessage.closeAll();
      const communityIdList = data.userFormData.communityIdLista.map(e => e.id);
      if (!data.isEdit) {
        newForm.value.validate((valid) => {
          if (valid) {
            addOneUser({
              userId: data.userFormData.userId.toString(),
              roleId: data.userFormData.roleId,
              communityIdList,
            });
            data.addUserBox = false;
          } else {
            ElMessage.warning({
              message: '请检查表单',
            });
          }
        });
      } else {
        editOneUser({
          userId: data.userFormData.userId.toString(),
          roleId: data.userFormData.roleId,
          communityIdList,
        });
      }
    };

    // 定义删除标签的方法----------------------------------------------------------------
    const delTag = (val) => {
      console.log(val);
      data.userFormData.communityIdLista.splice(val, 1);
      console.log(data.userFormData.communityIdLista);
    };

    // 定义获取表格数据方法
    const getUserTable = async (req) => {
      try {
        data.dataLoading = true;
        const res = await queryOperation(req);
        if (res.data?.ret === 0) {
          data.dataLoading = false;
          Object.keys(res.data.data.operationList).forEach((item) => {
            res.data.data.operationList[item].createDate = timetrans(res.data.data.operationList[item].createDate);
            res.data.data.operationList[item].createPostNum = res.data.data.operationList[item].createPostNum
              + res.data.data.operationList[item].replyPostNum;
          });
          data.dataTable = res.data.data.operationList;
          data.dataTable.length = res.data.data.pageInfo.total;
          data.dataToal = res.data.data.pageInfo.total;
        } else {
          data.dataLoading = false;
          ElMessage.error(res.data?.msg);
        }
      } catch (e) {
        console.log(e);
      }
    };

    // 定义打开数据窗口----------------------------------------------------------------
    const handleData = (val) => {
      console.log(val);
      const str = '...';
      data.addUserBox = true;
      data.newUser = false;
      data.dataPage = true;
      data.userSearchData.top = '15vh';
      data.userSearchData.width = '540px';
      data.userSearchData.title = '用户数据操作';
      data.dataPageData.nickName = val.nickName?.length > 10 ? (val.nickName.substring(0, 10) + str) : val.nickName;
      data.userSearchData.userId = val.userId;
      data.userFormData.userId = val.userId;
      data.dataPageData.userId = val.userId;
      getUserTable(data.dataPageData);
    };

    async function handleDownloadData(name, start, end) {
      const sendData = {
        pageNum: 1,
        pageSize: data.dataToal,
        userId: data.dataPageData.userId,
      };
      const res = await queryOperation(sendData);
      const table1 = res.data.data.operationList;
      const date1  = new Date();
      const date2  = new Date(date1);
      date2.setDate(date1.getDate() - 30);
      const agoDay = `${date2.getFullYear()}-${date2.getMonth() + 1 < 10 ? `0${date2.getMonth() + 1}` : date2.getMonth() + 1}-${date2.getDate()}`;
      const nowDay = `${date1.getFullYear()}-${date1.getMonth() + 1 < 10 ? `0${date1.getMonth() + 1}` : date1.getMonth() + 1}-${date1.getDate()}`;
      const day1 = end ? timetrans(end)  : nowDay;
      const day2 = start ? timetrans(start) : agoDay;
      // 导出表格的表头设置
      const allColumns = [
        {
          label: '日期',
          prop: 'createDate',
        },
        {
          label: '发帖量',
          prop: 'createPostNum',
        },
        {
          label: '帖子审核量',
          prop: 'auditPostNum',
        },
        {
          label: '回复审核量',
          prop: 'auditCommentNum',
        },
      ];
      const columnNames = [];
      const columnValues = [];
      for (let i = 0; i < allColumns.length; i++) {
        columnNames[i] = allColumns[i].label;
        columnValues[i] = allColumns[i].prop;
      }
      const table = [];
      Object.keys(table1).forEach((item) => {
        const bb = {
          ...table1[item],
        };
        table.push(bb);
      });
      require.ensure([], () => {
        const tHeader = columnNames;
        const filterVal = columnValues;
        const list = table;
        const data = formatJson(filterVal, list);
        exportJsonToExcel(tHeader, data, `${name}社区后台统计${day1}--${day2}`);
      });
    }
    function formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    }

    // 时间格式化
    const changeTime = (val) => {
      console.log(val[0].valueOf(), 'new Date(timetrans(val[0])).valueOf()');
      data.dataPageData.startTimeStamp = new Date(val[0]).valueOf()
        ? new Date(val[0]).valueOf() / 1000
        : undefined;
      data.dataPageData.endTimeStamp = new Date(val[1]).valueOf()
        ? new Date(val[1]).valueOf() / 1000
        : undefined;
    };
    // 时间戳转化
    const timetrans = (val) => {
      let date = '';
      date = dayjs.unix(val).format('YYYY-MM-DD');
      return date;
    };
    const dataSearch = () => {
      dataPageInfo.pageNum = 1;
      dataPageInfo.pageSize = 5;
      data.dataPageData.pageNum = 1;
      data.dataPageData.pageSize = 5;
      console.log(data.dataPageData, 'data.dataPageData');
      data.dataLoading = true;
      queryOperation(data.dataPageData).then((res) => {
        if (res.data?.ret === 0) {
          Object.keys(res.data.data.operationList).forEach((item) => {
            console.log(res.data.data.operationList[item].createDate);
            res.data.data.operationList[item].createDate = timetrans(res.data.data.operationList[item].createDate);
          });
          data.dataTable = res.data.data.operationList;
        } else {
          ElMessage.error(res.data?.msg);
        }
      })
        .finally(() => {
          data.dataLoading = false;
        });
    };

    // 打开编辑对话框
    const handleEdit = (val) => {
      console.log(val);
      data.addUserBox = true;
      data.newUser = true;
      data.dataPage = false;
      data.userSearchData.top = '25vh';
      data.userSearchData.width = '540px';
      data.userSearchData.title = '用户信息';
      data.userFormData.userId = val.userId;
      data.userFormData.roleId = val.roleId;
      data.userFormData.communityIdLista = [...val.communityList];
      data.isEdit = true;
    };

    // 删除功能-----------------------------------------
    const deleteRow = (index, rows, scope) => {
      ElMessageBox.confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const sendData = {
            userId: scope.userId,
          };
          delUser(sendData).then((res) => {
            if (res.data.ret === 0) {
              ElMessage.success({
                message: '删除成功',
              });
              getTableData({ ...data.searchForm });
            } else {
              ElMessage.warning({
                message: '删除失败',
              });
            }
          });
        })
        .catch(() => {
          ElMessage('取消删除');
        });
    };
    // 选择管理员时 添加关联用户清空
    const changeRoleId = (val) => {
      console.log(val, 'changid');
      if (val === 1) {
        data.userFormData.communityIdLista = [];
      }
    };
    return {
      addNewUser,
      data,
      pageInfo,
      getData,
      handleSizeChange,
      handleCurrentChange,
      dataHandleSizeChange,
      dataHandleCurrentChange,
      openAddCommunity,
      searchCom,
      close,
      handleAdd,
      addUserBtn,
      delTag,
      handleData,
      handleDownloadData,
      dataPageInfo,
      dataSearch,
      changeTime,
      handleEdit,
      deleteRow,
      communityPage,
      communityHandleSizeChange,
      communityHandleCurrentChange,
      filterRole,
      rules,
      newForm,
      timetrans,
      setPageInfo,
      getSearch,
      userInfo: computed(() => store.getters.userInfo),
      changeRoleId,
    };
  },
});
</script>

<style lang='scss'>
@import '../Post/index.scss';
</style>
