<template>
  <div class='userSearch'>
    <div class="searchPart">
      <el-button type="primary" class="addPostButton"
                 @click='addNew'> <i class="el-icon-plus addIcon">
      </i>创建用户</el-button>
      <input id="check" type="checkbox" />
      <label for="check" class="check-out">展开<i class="el-icon-arrow-down"></i></label>
      <label for="check" class="check-in">收起<i class="el-icon-arrow-up"></i></label>
      <div class="formPart element">
        <el-form label-position="top" label-width="80px" class="postForm" :model="data.userSearchData" ref='userFrom'>
          <el-form-item label="QQ号"
            prop="userId"
          >
            <el-input
            @input="mastNumber('userId')"
            placeholder="请输入" v-model="data.userSearchData.userId"></el-input>
          </el-form-item>
          <el-form-item label="关联社区ID"
            prop="communityId"
            :rules="[{
              validator:checkId, trigger:'blur'
            }]"
          >
            <el-input
            @input="mastNumber('communityId')"
            placeholder="请输入" v-model="data.userSearchData.communityId"></el-input>
          </el-form-item>
          <el-form-item label="关联社区名称" prop="communityName">
            <el-input placeholder="请输入" v-model="data.userSearchData.communityName"></el-input>
          </el-form-item>
          <el-form-item label="用户昵称" prop="nickName">
            <el-input placeholder="请输入" v-model="data.userSearchData.nickName"></el-input>
          </el-form-item>
          <el-form-item label="用户类型" prop="roleId">
            <el-select placeholder="请选择" v-model="data.userSearchData.roleId">
              <el-option v-for='(item, index) in data.selectOption'
                         :key='index'
                         :label='item.label'
                         :value='item.value'
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="check" @click='handleSearch'>检索</el-button>
            <el-button class="cancel" @click="clearSearchInfoFn">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, unref } from 'vue';
export default defineComponent({
  setup(props, { emit }) {
    // const pageInfo = inject('pageInfo');
    const userFrom = ref(null);
    const checkId = (rule, value, callBack) => {
      console.log(rule, 'rule');
      if (value) {
        if (Number(value) > 2147483647) {
          return callBack(new Error('输入ID无效'));
        }
      } else {
        callBack();
      }
    };
    const data = reactive({
      userSearchData: {
        userId: '', // 检索条件1（管理员可用 非必填 ）：QQ号
        nickName: '', // 检索条件2（管理员可用 非必填 ）：用户昵称
        roleId: null, // 检索条件3（管理员可用 非必填 ）：用户角色类型 1-管理员 2-厂商 3-版主 4-写手
        communityId: undefined, // 检索条件4（管理员可用 非必填）：关联社区ID
        communityName: '', // 检索条件5（管理员可用 非必填）：关联社区名称
      },
      selectOption: [
        {
          label: '全部',
          value: 0,
        },
        {
          label: '管理员',
          value: 1,
        },
        {
          label: '厂商',
          value: 2,
        },
        {
          label: '版主',
          value: 3,
        },
        {
          label: '写手',
          value: 4,
        },
      ],
    });
    // const getTableData = inject('getTableData');
    const handleSearch = () => {
      const form = unref(userFrom);
      if (!form) return;
      form.validate((valid) => {
        if (valid) {
          emit('getSearch', data.userSearchData);
          emit('getData', data.userSearchData);
        } else {
          return false;
        }
      });
      // getTableData(data.userSearchData);
    };

    // 重置方法
    const clearSearchInfoFn = () => {
      const form = unref(userFrom);
      form.resetFields();
      emit('setPageInfo', data.userSearchData);
    };

    // 打开新增用户弹窗
    const addNew = () => {
      emit('addNewUser');
      emit('getSearch', data.userSearchData);
      // getTableData(data.userSearchData);
    };
    const mastNumber = (key) => {
      Object.keys(data.userSearchData).forEach((k) => {
        if (key === k) {
          data.userSearchData[key] = (data.userSearchData[key].replace(/[^\d.]/g, ''));
        }
      });
    };
    return {
      data,
      handleSearch,
      clearSearchInfoFn,
      addNew,
      userFrom,
      mastNumber,
      checkId,
    };
  },
});
</script>

<style lang='scss'>
@import "../Post/index.scss";
</style>
